<template>
    <div class="content-container">
        <div id="home" style="background-color:white !important; color:black !important;">
            <!-- ======= Header ======= -->
            <header v-if="!isLoggedIn" id="header" class="fixed-top d-flex align-items-center header-transparent ">
                <div class="container d-flex align-items-center">

                    <div class="logo mr-auto">
                        <!--<h1 class="text-light"><a href="index.html">CryptoTaxSpace</a></h1>-->
                        <img src="../assets/CryptoTaxSpace_Logo.png" width="100" alt="logo" />
                        <!-- Uncomment below if you prefer to use an image logo -->
                        <!-- <a href="index.html"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->
                    </div>
                    <a id="mobileSignInButton" class="btn btn-primary float-right" style="border-radius:50px; font-size:14px; padding:5px 15px 7px 15px;" @click="gotoLoginPage()">Sign In</a>

                    <nav class="nav-menu d-none d-lg-block">
                        <ul>
                            <li v-bind:class="{active: activeTab === 'home'}"><a href="#" @click="scrollToSection('home')">Home</a></li>
                            <li v-bind:class="{active: activeTab == 'about'}"><a href="#" @click="scrollToSection('about')">About</a></li>
                            <li v-bind:class="{active: activeTab == 'pricing'}"><a href="#" @click="scrollToSection('pricing')">Pricing</a></li>
                            <li v-bind:class="{active: activeTab == 'faq'}"><a href="#" @click="scrollToSection('faq')">FAQ</a></li>
                            <li v-bind:class="{active: activeTab == 'contact'}"><a href="#" @click="scrollToSection('contact')">Contact</a></li>
                            <li><a id="desktopSignInButton" v-if="!isLoggedIn" class="btn btn-primary" @click="gotoLoginPage()">Sign In</a></li>
                        </ul>
                    </nav><!-- .nav-menu -->

                </div>
            </header><!-- End Header -->
            <!-- ======= Hero Section ======= -->
            <section id="hero" class="d-flex flex-column justify-content-end align-items-center" :style="primaryBackground">

                <div id="heroCarousel" class="container carousel carousel-fade" data-ride="carousel">

                    <!-- Slide 1 -->
                    <div class="carousel-item active">
                        <div class="carousel-container">
                            <img src="../assets/CryptoTaxSpace_Logo.png" width="350" alt="logo" />
                            <br />
                            <h2 class="animate__animated animate__fadeInDown">Welcome to <span>CryptoTaxSpace</span></h2>
                            <p class="animate__animated fanimate__adeInUp">Hi there. We are sure you are looking for that easy to use, cost-efficient, cryptocurrency tax file creator.</p>
                            <a href="#" @click="scrollToSection('about')" class="btn-get-started animate__animated animate__fadeInUp scrollto">Read More</a>

                        </div>
                    </div>

                    <!-- Slide 2 -->
                    <div class="carousel-item">
                        <div class="carousel-container">
                            <img src="../assets/CryptoTaxSpace_Logo.png" width="350" alt="logo" />
                            <br />
                            <h2 class="animate__animated animate__fadeInDown">You have come to the right <strike>place</strike> <span style="color:var(--var-theme-color);">Space</span></h2>
                            <p class="animate__animated animate__fadeInUp">We do not overcharge to just combine and create your crypto tax file.  Upload yours and try us out.</p>
                            <a href="#" @click="scrollToSection('about')" class="btn-get-started animate__animated animate__fadeInUp scrollto">Read More</a>
                        </div>
                    </div>

                    <!-- Slide 3 -->
                    <div class="carousel-item">
                        <div class="carousel-container">
                            <img src="../assets/CryptoTaxSpace_Logo.png" width="350" alt="logo" />
                            <br />
                            <h2 class="animate__animated animate__fadeInDown">Easy 5 steps to create your file</h2>
                            <p class="animate__animated animate__fadeInUp">Do not spend hours and hours trying to fill out your tax forms for gains and losses on cryptocurrency.  Let us do it in our secure portal for instant access to your file.</p>
                            <a href="#" @click="scrollToSection('about')" class="btn-get-started animate__animated animate__fadeInUp scrollto">Read More</a>
                        </div>
                    </div>

                    <a class="carousel-control-prev" href="#heroCarousel" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon bx bx-chevron-left" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>

                    <a class="carousel-control-next" href="#heroCarousel" role="button" data-slide="next">
                        <span class="carousel-control-next-icon bx bx-chevron-right" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>

                </div>

                <svg class="hero-waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28 " preserveAspectRatio="none">
                    <defs>
                        <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                    </defs>
                    <g class="wave1">
                        <use xlink:href="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)" />
                    </g>
                    <g class="wave2">
                        <!--<use xlink:href="#wave-path" x="50" y="0" fill="rgba(239,102,3, .3)" />-->
                        <!--<use xlink:href="#wave-path" x="50" y="0" fill="rgba(235,45,0, .3)" />-->
                        <!--<use xlink:href="#wave-path" x="50" y="0" fill="rgba(222,163,68, .3)" />-->
                        <use xlink:href="#wave-path" x="50" y="0" fill="rgba(10,193,18, .3)" />

                    </g>
                    <g class="wave3">
                        <use xlink:href="#wave-path" x="50" y="9" fill="var(--var-theme-color)" />
                    </g>
                </svg>

            </section><!-- End Hero -->

            <main id="main">

                <!-- ======= About Section ======= -->
                <section id="about" class="about">
                    <div class="container">

                        <div class="section-title" data-aos="zoom-out">
                            <h2>About</h2>
                            <p>Who we are</p>
                        </div>

                        <div class="row content" data-aos="fade-up">
                            <div class="col-lg-6">
                                <p>
                                    CryptoTaxSpace was founded upon the notion that it shouldn't be rocket science to get a file of your crypto transactions that is importable into TurboTax.  Yes, there are services out there that you can purchase for $49 on up.  This gets pricey when you have some transactions to report let alone the time it would take to manually sift through your raw files to come up with your gains and losses.  We here at CryptoTaxSpace don't believe in a la carte pricing based on how many transactions you did over the year. We believe that having a low price of $8 is good for everyone.  Why pay more?  We would rather help a lot of our fellow crypto traders than just some of you.  As we all go to the moon, rest assured that CryptoTaxSpace will be by your side each year during tax season.  Quick, easy, efficient, and cost effective are our guiding principles.  We thank all of you for joining our CryptoTaxSpace community.
                                </p>
                                <ul>
                                    <li><i class="ri-check-double-line"></i> Secure:  We only parse your data, never stored.</li>
                                    <li><i class="ri-check-double-line"></i> Cost Effective:  The most economical crypto tax file prep on the Internet.</li>
                                    <li><i class="ri-check-double-line"></i> Simple:  5 easy steps to create your crypto tax file a stress-free experience.  Spend minutes instead of days.</li>
                                </ul>
                            </div>
                            <div class="col-lg-6 pt-4 pt-lg-0">
                                <p>
                                    We want you to spend your free time doing something fun and not worrying about
                                    your cryptocurrency tax file preparation.  We will create your TurboTax file
                                    instantly and you can move onto more important things.
                                </p>
                                <a href="#" @click="scrollToSection('features')" class="btn-learn-more">Learn More</a>
                            </div>
                        </div>

                    </div>
                </section><!-- End About Section -->
                <!-- ======= Features Section ======= -->
                <section id="features" class="features">
                    <div class="container">

                        <ul class="nav nav-tabs row d-flex">
                            <li class="nav-item col-3" data-aos="zoom-in">
                                <a class="nav-link active show" data-toggle="tab" href="#tab-1">
                                    <i class="ri-gps-line"></i>
                                    <h4 class="d-none d-lg-block">Download your file.</h4>
                                </a>
                            </li>
                            <li class="nav-item col-3" data-aos="zoom-in" data-aos-delay="100">
                                <a class="nav-link" data-toggle="tab" href="#tab-2">
                                    <i class="ri-body-scan-line"></i>
                                    <h4 class="d-none d-lg-block">Setup and login to CryptoTaxSpace.</h4>
                                </a>
                            </li>
                            <li class="nav-item col-3" data-aos="zoom-in" data-aos-delay="200">
                                <a class="nav-link" data-toggle="tab" href="#tab-3">
                                    <i class="ri-sun-line"></i>
                                    <h4 class="d-none d-lg-block">Select your options.</h4>
                                </a>
                            </li>
                            <li class="nav-item col-3" data-aos="zoom-in" data-aos-delay="300">
                                <a class="nav-link" data-toggle="tab" href="#tab-4">
                                    <i class="ri-store-line"></i>
                                    <h4 class="d-none d-lg-block">Poof</h4>
                                </a>
                            </li>
                        </ul>

                        <div class="tab-content" data-aos="fade-up">
                            <div class="tab-pane active show" id="tab-1">
                                <div class="row">
                                    <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                                        <h3>Step 1:  Download your tax file from your crypto exchange (currently support Coinbase, Coinbase Pro, and Robinhood) and save to your computer.</h3>
                                        <p class="font-italic">
                                            This has no personally identifiable information and CryptoTaxSpace stores no information related.  For instructions on downloading your tax files from Coinbase, Coinbase Pro, or Robinhood go here to view instructions:
                                        </p>
                                        <ul>
                                            <li><i class="ri-check-double-line"></i> <a href="https://www.coinbase.com/reports">Coinbase</a>  </li>
                                            <li><i class="ri-check-double-line"></i> <a href="https://robinhood.com/account/documents">Robinhood</a> </li>
                                        </ul>
                                        <p>
                                            We are here to help you along the way to get your crypto tax file.  Here are some additional helpful links.  We do not provide tax advice, but want you to have
                                            all the information necessary.  $8 for your tax file at CryptoTaxSpace.com.  Checkout this link from Coinbase for more information on their files:

                                            <a href="https://www.coinbase.com/learn/tips-and-tutorials/crypto-and-bitcoin-taxes-US">Tutorials</a>
                                        </p>
                                    </div>
                                    <div class="col-lg-6 order-1 order-lg-2 text-center">
                                        <img src="../assets/home/img/features-1.png" alt="" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane" id="tab-2">
                                <div class="row">
                                    <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                                        <h3>Step 2:  Setup your account and login to CryptoTaxSpace and click "Taxes"</h3>
                                        <p>
                                            We do not store any data except your email address so you can login.  You will want to save us as a favorite and look forward to seeing you each year.
                                        </p>
                                        <p class="font-italic">
                                            Setting up an account should not take rocket science, setting up API keys to your accounts, etc.  We do not ask for any of that.
                                        </p>
                                        <ul>
                                            <li><i class="ri-check-double-line"></i> Lightweight </li>
                                            <li><i class="ri-check-double-line"></i> Easy </li>
                                            <li><i class="ri-check-double-line"></i> Fast </li>
                                            <li><i class="ri-check-double-line"></i> Your data, not ours </li>
                                        </ul>
                                    </div>
                                    <div class="col-lg-6 order-1 order-lg-2 text-center">
                                        <img src="../assets/home/img/features-2.png" alt="" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane" id="tab-3">
                                <div class="row">
                                    <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                                        <h3>Step 3:  Select Tax Year, Exchange, Tax Method (HIFO, LIFO, FIFO), and Upload Tax File (select the tax file you downloaded in Step 1</h3>
                                        <p>
                                            You will see your file processed and it will show you the transactions on screen.  If you see "Uncovered Transactions" this means your sells do not have enough buys to cover the sells.  Please verify all your data is in the tax file.
                                        </p>
                                        <ul>
                                            <li><i class="ri-check-double-line"></i> If you have more than 1 file, repeat Step 1.  </li>
                                            <li><i class="ri-check-double-line"></i> Once done importing all your files, Select "Download CSV" and save it to a location on your computer. </li>
                                            <li><i class="ri-check-double-line"></i> You now have your TurboTax cryptocurrency tax file ready to import into TurboTax. </li>
                                        </ul>
                                        <p class="font-italic">
                                            If buying/selling cryptocurrency we recommend using the online version of TurboTax as the online version has direct import of crypto tax files.  The desktop CD version of TurboTax does not support importing of any crypto tax files from any exchanges.
                                        </p>
                                    </div>
                                    <div class="col-lg-6 order-1 order-lg-2 text-center">
                                        <img src="../assets/home/img/features-3.png" alt="" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane" id="tab-4">
                                <div class="row">
                                    <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                                        <h3> Complete your payment and download your file </h3>
                                        <p>
                                            That was pretty easy, right?
                                        </p>
                                        <p class="font-italic">
                                            We understand there are a lot of exchanges out there.  If you have a file that we do not support shoot us an email and we can see about supporting it.
                                        </p>
                                        <ul>
                                            <li><i class="ri-check-double-line"></i> Your one stop shop for easy crypto tax file creation. </li>
                                            <li><i class="ri-check-double-line"></i> With the time you just saved in your day what are you going to do? </li>
                                            <li><i class="ri-check-double-line"></i> Yes, it is pretty amazing, we know. </li>
                                        </ul>
                                    </div>
                                    <div class="col-lg-6 order-1 order-lg-2 text-center">
                                        <img src="../assets/home/img/features-4.png" alt="" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section><!-- End Features Section -->
                <!-- ======= Cta Section ======= -->
                <section v-if="!isLoggedIn" id="cta" class="cta" :style="secondaryBackground">
                    <div class="container">

                        <div class="row" data-aos="zoom-out">
                            <div class="col-lg-9 text-center text-lg-left">
                                <h3>Get Started</h3>
                                <p> Real easy to get started.  Your email, your secured password, and then confirm your account in email.  This is just to ensure we have the right person.  </p>
                            </div>
                            <div class="col-lg-3 cta-btn-container text-center">
                                <a class="cta-btn align-middle" href="#" @click="linkTo('newaccount')">Sign-up Now</a>
                            </div>
                        </div>

                    </div>
                </section><!-- End Cta Section -->
                <!-- ======= Pricing Section ======= -->
                <section id="pricing" class="pricing">
                    <div class="container">

                        <div class="section-title" data-aos="zoom-out">
                            <h2>Pricing</h2>
                            <p>Our Competing Prices</p>
                        </div>

                        <div class="row">

                            <div class="col-lg-3 col-md-6">
                                <div class="box featured" data-aos="zoom-in" style="height:500px;">
                                    <span class="advanced" style="background-color:darkred;">#1 Choice</span>
                                    <h3>CryptoTaxSpace</h3>
                                    <h4><sup>$</sup>8<span> / tax season</span></h4>
                                    <ul>
                                        <li>Full Access</li>
                                        <li>No gimmicks</li>
                                        <li>No upsells; we show you all your tax options HIFO, FIFO, LIFO</li>
                                        <li class="na">Per transaction quantity</li>
                                        <li class="na">Sliding price scale</li>
                                    </ul>
                                    <div class="btn-wrap">
                                        <a v-if="!isLoggedIn" href="#" @click="linkTo('newaccount')" class="btn-buy" style="background-color:var(--var-theme-color)">Buy Now</a>
                                        <span v-if="hasSubscription" class="btn-buy" style="background-color:var(--var-theme-color)">Active Member</span>
                                        <a v-if="isLoggedIn && !hasSubscription" href="#" @click="purchaseLink" class="btn-buy" style="background-color:var(--var-theme-color)">Buy Now</a>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6">
                                <div class="box featured" data-aos="zoom-in" data-aos-delay="100" style="height:500px;">
                                    <h3 class="priceBackgroundColor">Competitors</h3>
                                    <h4 class="priceTextColor"><sup>$</sup>49<span> / on up</span></h4>
                                    <ul>
                                        <li>You pay for transaction quantity</li>
                                        <li>Difficult to setup</li>
                                        <li>They store your data</li>
                                        <li>Need sensitive information to access your exchange</li>
                                        <li>Hidden charges</li>
                                    </ul>
                                    <div class="btn-wrap">
                                        <span class="btn-buy priceBackgroundColor">Overpriced</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6">
                                <div class="box featured" data-aos="zoom-in" data-aos-delay="200" style="height:500px;">
                                    <h3 class="priceBackgroundColor">Yourself</h3>
                                    <h4 class="priceTextColor"><sup>$</sup>1000<span style="font-size:small;"> / worth of your time</span></h4>
                                    <ul>
                                        <li>Time consuming</li>
                                        <li>Prone to accuracy errors</li>
                                        <li>Too many distractions</li>
                                        <li>How do you track everything?</li>
                                        <li>Could take days or weeks to accomplish</li>
                                    </ul>
                                    <div class="btn-wrap">
                                        <span class="btn-buy priceBackgroundColor">Too Much Work</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6">
                                <div class="box featured" data-aos="zoom-in" data-aos-delay="300" style="height:500px;">
                                    <h3 class="priceBackgroundColor">Choosing Not to Do</h3>
                                    <h4 class="priceTextColor"><sup>$</sup>???<span style="font-size:small;"> / years to come</span></h4>
                                    <ul>
                                        <li>Tax evasion</li>
                                        <li>IRS will know</li>
                                        <li>Potential audit</li>
                                        <li>Back taxes & fines</li>
                                        <li>Just pay the $8 at CryptoTaxSpace</li>
                                    </ul>
                                    <div class="btn-wrap">
                                        <span class="btn-buy priceBackgroundColor">Risky</span>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </section><!-- End Pricing Section -->
                <!-- ======= F.A.Q Section ======= -->
                <section id="faq" class="faq">
                    <div class="container">

                        <div class="section-title" data-aos="zoom-out">
                            <h2>F.A.Q</h2>
                            <p>Frequently Asked Questions</p>
                        </div>

                        <ul class="faq-list">

                            <li data-aos="fade-up" data-aos-delay="100">
                                <a data-toggle="collapse" class="" href="#faq1"> Short visit to IRS FAQ; we will wait for you <i class="icofont-simple-up"></i></a>
                                <div id="faq1" class="collapse show" data-parent=".faq-list">
                                    <p class="mt-2">
                                        <a style="color:blue;" href="https://www.irs.gov/individuals/international-taxpayers/frequently-asked-questions-on-virtual-currency-transactions" target="_blank">IRS FAQ</a>
                                    </p>
                                </div>
                            </li>

                            <li data-aos="fade-up" data-aos-delay="200">
                                <a data-toggle="collapse" href="#faq2" class="collapsed">Just some more bitcoin info, but we are here for you <i class="icofont-simple-up"></i></a>
                                <div id="faq2" class="collapse" data-parent=".faq-list">
                                    <p class="mt-2">
                                        <a style="color:blue;" href="https://bitcoin.tax/faq" target="_blank">Bitcoin Tax Info</a>
                                    </p>
                                </div>
                            </li>

                            <li data-aos="fade-up" data-aos-delay="300">
                                <a data-toggle="collapse" href="#faq3" class="collapsed">Have to mention Coinbase tutorial, but we'll sit tight for your return <i class="icofont-simple-up"></i></a>
                                <div id="faq3" class="collapse" data-parent=".faq-list">
                                    <p class="mt-2">
                                        <a style="color:blue;" href=" https://www.coinbase.com/learn/tips-and-tutorials/crypto-and-bitcoin-taxes-US">Coinbase Tutorial</a>
                                    </p>
                                </div>
                            </li>

                            <li data-aos="fade-up" data-aos-delay="400">
                                <a data-toggle="collapse" href="#faq4" class="collapsed">What is HIFO?  Here it is.  Easy peasy. <i class="icofont-simple-up"></i></a>
                                <div id="faq4" class="collapse" data-parent=".faq-list">

                                    <p class="mt-2" style="color:blue;">

                                        Highest-in first-out (HIFO) works exactly how it sounds. You sell the coins with the highest cost basis (original purchase price) first.

                                        HIFO can be used as a "tax minimization" method as it will lead to the lowest capital gains and the largest capital losses, best of both worlds. Keep in mind, net capital losses can be used to offset other income up to $3,000 dollars (the remaining will be carried forward to future tax years).

                                        Using our example above, HIFO would actually lead to the same total gain as LIFO. But you can imagine how a scenario with hundreds of trades would work-selling your highest cost basis coins first to minimize your capital gains.

                                    </p>

                                </div>
                            </li>

                            <li data-aos="fade-up" data-aos-delay="500">
                                <a data-toggle="collapse" href="#faq5" class="collapsed">What is LIFO?  No sweat, check it out. <i class="icofont-simple-up"></i></a>
                                <div id="faq5" class="collapse" data-parent=".faq-list">

                                    <p class="mt-2" style="color:blue;">

                                        LIFO works exactly opposite of FIFO. Instead of selling off the first coins you acquired, you sell the last coins that came in (i.e. the most recent coins you acquired).

                                        To illustrate this further, let's use the exact same example from above.

                                        Using LIFO, our cost basis (or original purchase price) of the 5 ETH that we sold off in June would be $2,800 ($600 + $600 + $600 + $500 + $500).

                                        Doing the math then:

                                        $3,000 (selling price) - $2,800 (purchase price or cost basis) = $200 capital gain

                                        As you can see with LIFO, we pull from the latest coins we bought-in this case we bought 3 ETH for $600, so we sell this group of coins first. Once that group is gone, we sell the 2 ETH that were bought for $500. In this example, using LIFO led to a $200 total gain where FIFO led to a $500 total capital gain.

                                        In a period of rising cryptocurrency prices, using LIFO will lead to significantly less total taxable gains. It will also shield your earliest purchased cryptocurrency from getting "sold" off right away. This can lead to other benefits like extending your cryptocurrency holding period. Remember, long-term capital gains (assets held longer than one year before being disposed of) are taxed at the long-term capital gains rate, which is less than the short term capital gains tax rate.

                                    </p>

                                </div>
                            </li>

                            <li data-aos="fade-up" data-aos-delay="600">
                                <a data-toggle="collapse" href="#faq6" class="collapsed">What is FIFO?  Not our pet dog, but that would be a cool dog name. <i class="icofont-simple-up"></i></a>
                                <div id="faq6" class="collapse" data-parent=".faq-list">

                                    <p class="mt-2" style="color:blue;">

                                        Using first-in-first-out works exactly how it sounds. The first coin that you purchase (chronologically) is the first coin that is counted for a sale.
                                        An example:

                                        You buy 10 ETH on Coinbase on January 15th, 2018 for $500 each. Two weeks later, you buy 3 more for $600. In June 2018, you sell 5 ETH for $3,000 total.
                                        How does this work using FIFO?

                                        To calculate your capital gain on this transaction, you need to know at what price you purchased those ETH.

                                        Because we are using FIFO, the purchase price of those 5 ETH that you sold in June will be $2,500 as each one was purchased for $500 in the first group that you bought (5 * $500).

                                        Doing the math then:

                                        $3,000 (selling price) - $2,500 (purchase price or cost basis) = $500 capital gain.

                                        As you can see, by using FIFO, we sell the coins that we purchased first. First in, first out.

                                    </p>

                                </div>
                            </li>

                        </ul>

                    </div>
                </section><!-- End F.A.Q Section -->
                <!-- ======= Contact Section ======= -->
                <section id="contact" class="contact">
                    <div class="container">

                        <div class="section-title" data-aos="zoom-out">
                            <h2>Contact</h2>
                            <p>Reach Out to Us. We love feedback.</p>
                        </div>

                        <div class="row mt-5">

                            <div class="col-lg-4" data-aos="fade-right">
                                <div class="info" style="background-color:white !important;">
                                    <div class="email">
                                        <i class="icofont-envelope"></i>
                                        <h4>Support:</h4>
                                        <p>support@cryptotaxspace.com</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-8 mt-5 mt-lg-0" data-aos="fade-left">

                                <form class="php-email-form" @submit.prevent="sendFeedback">
                                    <div class="form-row">
                                        <div class="col-md-6 form-group">
                                            <input type="text" v-model="feedbackContactName" name="name" class="form-control" id="name" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                                            <div class="validate"></div>
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <input type="email" v-model="feedbackContactEmail" class="form-control" name="email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" />
                                            <div class="validate"></div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <input type="text" v-model="feedbackSubject" class="form-control" name="subject" id="subject" placeholder="Subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
                                        <div class="validate"></div>
                                    </div>
                                    <div class="form-group">
                                        <textarea class="form-control" v-model="feedbackMessage" name="message" rows="5" data-rule="required" data-msg="Please write something for us" placeholder="Message"></textarea>
                                        <div class="validate"></div>
                                    </div>
                                    <div>
                                        <div v-if="isSendingFeedback" class="loading">Sending</div>
                                        <div v-if="error" class="error-message">{{error}}</div>
                                        <div v-if="isFeedbackSent" class="sent-message">Your message has been sent. Thank you!</div>
                                    </div>
                                    <div v-if="!disableFeedbackSubmit" class="text-center">
                                        <button id="btnSendFeedback" :disabled="disableFeedbackSubmit" @click="sendFeedback">
                                            Send Message
                                        </button>
                                    </div>
                                </form>

                            </div>

                        </div>

                    </div>
                </section><!-- End Contact Section -->

            </main><!-- End #main -->
            <!-- ======= Footer ======= -->
            <footer id="footer" :style="secondaryBackground">
                <div class="container">
                    <h3>CryptoTaxSpace</h3>
                    <p>Finally here!  No more overpaying for a crypto tax file showing your gains and losses.  Create your file and import into TurboTax Online today.</p>
                    <div class="social-links">
                        <a href="https://twitter.com/cryptotaxspace" class="twitter" target="_blank"><i class="bx bxl-twitter"></i></a>
                        <!--<a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>-->
                        <!--<a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>-->
                        <!--<a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>-->
                        <!--<a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>-->
                    </div>
                    <div class="copyright">
                        &copy; Copyright <strong><span>CryptoTaxSpace</span></strong>. All Rights Reserved
                    </div>

                </div>
            </footer><!-- End Footer -->

            <a href="#" @click="scrollToSection('home')" class="back-to-top"><i class="ri-arrow-up-line"></i></a>
        </div>
    </div>
</template>

<script>

    import { mapGetters, mapActions } from 'vuex';
    import { serviceHelper } from '../shared/service-helper';
    import { feedbackService } from '@/services/feedback-service';

    export default {
        name: 'Home',

        /* COMPONENT DATA  */
        data() {
            return {
                activeTab: 'home',
                isScrolling: false,
                timer: null,
                error: null,
                isSendingFeedback: false,
                isFeedbackSent: false,
                feedbackContactName: "",
                feedbackContactEmail: "",
                feedbackSubject: "",
                feedbackMessage: "",
                primaryBackground: {
                    backgroundImage: `url(${require('@/assets/dark-space-4.jpg')})`
                },
                secondaryBackground: {
                    backgroundImage: `url(${require('@/assets/secondarySpace.jpg')})`
                },
                options: {
                    duration: 400,
                    offset: 50,
                    easing: 'easeOutCubic',
                }
            };
        },

        /* PROPS */
        props: {
            Section: {
                type: String,
                default: () => 'home',
            }
        },

        /* WATCHERS */
        watch: {

            scrollTab: function (val, oldVal) {
                //A unique value gets passed in to force watcher to be updated so the scroll event can be triggered.
                //If user navigates via the nav menu, this will always force scroll to that particular section.
                //However if a user was scrolling from within the page to a another activeTab (goToSection/handleScroll), the scroll tab wouldn't change
                //which was causing this watcher to never fire when the button was re-pressed from the nav menu.
                //This is a work around by forcing the page to scroll to designated area even if that link is already active.
                const values = val.split("||");
                var path = values[0];
                if (path !== "mytaxes") {
                    this.isScrolling = false;
                    this.scrollToSection(path, true);       
                }
            }
        },

        /* LIFE CYCLE METHODS  */
        created() {
            window.addEventListener('scroll', this.handleScroll);
        },

        destroyed() {
            window.removeEventListener('scroll', this.handleScroll);
        },

        async mounted() {
            this.currentYear = new Date().getFullYear();
            if (this.Section !== undefined && this.Section !== null)
                this.scrollToSection(this.Section, true);
        },

        /* COMPONENT METHODS  */
        methods: {

            ...mapActions(['setActiveTabAction', 'setScrollTabAction']),

            gotoLoginPage() {
                this.$router.push({ name: 'login' });
            },

            linkTo(link) {
                this.setActiveTabAction(link);
                this.$router.push({ name: link }).catch(err => { });
            },

            purchaseLink() {
                this.setActiveTabAction('mytaxes');
                this.$router.push({ name: "mytaxes", params: { Action: 'subscribe' } }).catch(err => { });
            },

            scrollToSection(section, smoothDelay) {

                clearTimeout(this.timer);

                if (section !== "features") {
                    this.activeTab = section; //Set the active tab which is referenced during the handleScroll event
                }
              
                //Prevent this code from being spammed
                if (this.isScrolling === false) {
                    this.isScrolling = true;

                    //Smooth delay is necessary when scrolling is triggered from the watcher, otherwise there is a flicker.
                    //No delay is necessary when triggered from the screen itself, otherwise it doesn't scroll, but skips to section.
                    if (smoothDelay) {
                        setTimeout(() => {
                            this.$vuetify.goTo("#" + section, this.options);
                        }, 50);
                    } else {
                        this.$vuetify.goTo("#" + section, this.options);
                    }

                    //Highlight the active tab in the navmenu
                    if (this.activeTab !== "features") {
                        this.setActiveTabAction(this.activeTab);
                    }

                    //Timer is necessary so the handleScroll code won't fire while the form is being auto-scrolled
                    this.timer = setTimeout(() => {
                        this.isScrolling = false;
                    }, 400);
                }
            },

            handleScroll(event) {

                if (this.isScrolling !== true) {

                    var scrollPosition = window.scrollY;

                    //Add buffer for top page because it always set home to the active tab otherwise
                    if (scrollPosition === 0 || scrollPosition < 100) {
                        this.activeTab = "home";
                    }
                    else {
                        if (this.isInViewport("home"))
                            this.activeTab = "home";

                        if (this.isInViewport("about"))
                            this.activeTab = "about";

                        if (this.isInViewport("pricing"))
                            this.activeTab = "pricing";

                        if (this.isInViewport("faq"))
                            this.activeTab = "faq";

                        if (this.isInViewport("contact"))
                            this.activeTab = "contact";
                    }

                    //Set the nav menu's active tab if the user has manually scrolled to a new section
                    if (this.currentTab !== this.activeTab) {
                        if (this.activeTab !== "features") {
                            this.setActiveTabAction(this.activeTab);
                        }
                    }
                }

            },

            isInViewport(elementId) {
                var element = document.getElementById(elementId);
                if (element == null) return;
                const rect = element.getBoundingClientRect();
                return (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                );
            },

            async sendFeedback() {

                //Do nothing if already sending
                if (this.isSendingFeedback) return;

                //Validate fields
                if (!this.validateFeedbackFields()) return;

                this.isSendingFeedback = true;
                this.error = null;

                var feedback =
                {
                    contactName: this.feedbackContactName,
                    contactEmail: this.feedbackContactEmail,
                    subject: this.feedbackSubject,
                    message: this.feedbackMessage
                };

                await serviceHelper.simulateAwait(true, 2500);

                var feedbackResponse = await feedbackService.addFeedback(feedback);
                if (feedbackResponse.success) {
                    this.isFeedbackSent = true;
                }
                else {
                    this.error = feedbackResponse.error.replace(/['"]+/g, '');
                }
                this.isSendingFeedback = false;
            },

            validateFeedbackFields() {

                if (!this.validateEmail(this.feedbackContactEmail)) {
                    this.error = "Invalid email";
                    return false;
                }

                if (this.feedbackContactName.trim() === "") {
                    this.error = "Contact name cannot be empty";
                    return false;
                }

                if (this.feedbackSubject.trim() === "") {
                    this.error = "Subject cannot be empty";
                    return false;
                }

                if (this.feedbackMessage.trim() === "") {
                    this.error = "Message cannot be empty";
                    return false;
                }
                return true;
            },

            validateEmail(email) {
                return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
            },
        },

        /* COMPUTED PROPERTIES */
        computed: {
            ...mapGetters(['isLoggedIn', 'scrollTab', 'currentTab']),

            hasSubscription() { return this.$store.getters.hasRequiredRole('Premium User'); },

            disableFeedbackSubmit() {
                return this.isSendingFeedback || this.isFeedbackSent;
            }
        },
    }
</script>

<style scoped>

    .priceBackgroundColor{
        background-color:royalblue !important;
    }

    .priceTextColor {
        color: royalblue !important;
    }

    #btnSendFeedback {
        background-color: var(--var-theme-color) !important;
        border-color: var(--var-theme-color) !important;
        border-radius: 50px;
        color: white;
        font-size: 14px;
        padding: 5px 15px 7px 15px;
    }

        #btnSendFeedback:hover {
            --var-theme-hover-color: #E94F2B;
        }

    /* Mobile */
    @media (max-width: 740.98px) {

        #mobileSignInButton {
            display: inline-block !important;
        }

            #mobileSignInButton:hover {
                background-color: var(--var-theme-color) !important;
                border-color: var(--var-theme-color) !important;
            }
    }

    /* Desktop */
    @media (min-width: 741px) {

        #mobileSignInButton {
            display: none !important;
        }

        #desktopSignInButton:hover {
            background-color: var(--var-theme-color) !important;
            border-color: var(--var-theme-color) !important;
        }
    }
</style>


<style scoped src="@/assets/home/vendor/bootstrap/css/bootstrap.min.css"></style>
<style scoped src="@/assets/home/vendor/icofont/icofont.min.css"></style>
<style scoped src="@/assets/home/vendor/boxicons/css/boxicons.min.css"></style>
<style scoped src="@/assets/home/vendor/animate.css/animate.min.css"></style>
<style scoped src="@/assets/home/vendor/remixicon/remixicon.css"></style>
<style scoped src="@/assets/home/vendor/line-awesome/css/line-awesome.min.css"></style>
<style scoped src="@/assets/home/vendor/venobox/venobox.css"></style>
<style scoped src="@/assets/home/vendor/owl.carousel/assets/owl.carousel.min.css"></style>
<style scoped src="@/assets/home/vendor/aos/aos.css"></style>
<style scoped src="@/assets/home/css/style.css"></style>
