/* service-helper.js provides utility functions for dealing the API calls to and from the server */

import store from '../store';
import jwt_decode from 'jwt-decode';

//Generic API function that accepts JSON and returns a default JSON result or blob result if isFile is true
export async function callApi(url, methodType, data, isFile) {

    var headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${store.getters.token}`
    };
    var body = JSON.stringify(data);
    var result = await _callApiBase(url, methodType, body, headers, isFile);
    return result;
}

//Internal function that makes the AJAX call
export async function _callApiBase(url, methodType, data, apiHeaders, isFile) {
    var result = { success: false, value: null, error: "" };
    try {
        const response = await fetch(url,
            {
                method: methodType,
                headers: apiHeaders,
                body: data
            });
        result = await _parseApiResponse(response, isFile);
    } catch (error) {
        result.success = false;
        result.error = error;
    }
    return result;
}

//Internal function for formatting all API responses into a generic response object
async function _parseApiResponse(response, isFile) {
    //All authenticated API trips should resolve with the following object 
    var result = { success: false, value: null, error: "" };
    if (response.ok) {
        result.success = true;
        if (isFile)
            result.value = await response.blob(); //Successful calls from the server will always return a valid file object
        else
            result.value = await response.json(); //Successful calls from the server will always return a valid object
    }
    else {
        result.success = false;
        result.error = await response.text(); //Unsuccessful calls from the server will always have a text explanation
    }
    return result;
}

//Testing function to simulate a long running server operation
function simulateAwait(data, sleep) {
    return new Promise(resolve => {
        setTimeout(() => resolve(data), sleep);
    });
}

//Function used by services to validate token before executing API call
export function validateToken() {
    const decoded = jwt_decode(store.getters.token);
    if (decoded.exp - (Date.now() / 1000) < 0) {
        //Logout and redirect if the token is expired
        store.dispatch('logoutUserAction');
        return false;
    }
    return true;
}

export const serviceHelper = {

    callApi,
    simulateAwait,
    validateToken
};