//Avoid magic strings and use these global constants instead

export const displayDateTimeFormat = 'MMM-dd-yyyy h:mm:ss a';
export const displayShortDateDescFormat = 'MMM/dd/yyyy';
export const displayShortDateFormat = 'MM/dd/yy';
export const displayShortDateYearFormat = 'MM/dd/yyyy';
export const displayFullDate = "MMMM, dd yyyy";
export const HTTP_POST = "POST";
export const HTTP_GET = "GET";
export const HTTP_DELETE = "DELETE";
export const HTTP_PUT = "PUT";
