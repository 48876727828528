<template>
    <form class="cts-form-body" @submit.prevent="login">
        <div class="cts-wrapper" :style="flashBackground">
        </div>
    </form>
</template>

<script>

    export default {
        name: 'ComingSoon',

        /* COMPONENT DATA  */
        data() {
            return {
                flashBackground: {
                    backgroundImage: `url(${require('@/assets/CTS_Drop.jpg')})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundColor: "black !important;"
                },
            };
        },
    };
</script>

<style scoped>

    .center {
        background-color: transparent;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%); /* for IE 9 */
        -webkit-transform: translate(-50%, -50%); /* for Safari */
    }
</style>