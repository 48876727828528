import { API } from '../shared/config';
import { HTTP_POST  } from '../shared/constants';
import { serviceHelper } from '../shared/service-helper';

const addFeedback = async function (feedbackArgs) {
    try {
        const response = await serviceHelper.callApi(`${API}/InsertFeedback`, HTTP_POST, feedbackArgs);
        return response;
    } catch (error) {
        return null;
    }
}

export const feedbackService = {
    addFeedback
};

